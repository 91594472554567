
import {Component, Watch} from 'vue-property-decorator';
import {calendarModule} from '@/modules/calendar/shared/state/module';
import {Calendar, CalendarData, CalendarOptions, Duration, EventClickArg} from '@fullcalendar/core';

import {ModuleProps} from '@/shared/state/template/module-props';
import ListComponent from '@/shared/components/layout/list/list-component';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, {DateClickArg, EventResizeDoneArg} from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import plLocale from '@fullcalendar/core/locales/pl';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import {CalendarEventDataRequest} from '@/modules/calendar/shared/requests/calendar-event-data-request';
import {CalendarEvent} from '@/modules/calendar/shared/models/calendar-event';
import {
    DateSelectArg,
    DayHeaderContentArg,
    EventApi,
    EventDropArg,
    EventInput,
    EventSourceApi,
    NowIndicatorContentArg
} from '@fullcalendar/vue';
import EventForm from '@/modules/calendar/components/form.vue';
import {eventAssignables, viewTypes} from '@/shared/config/calendar';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import {SaveResponse} from '@/shared/types';
import EventDetails from './details.vue';
import SystemBar from '@/shared/components/elements/system-bar.vue';
import {ModuleShowPayload} from '@/shared/state/template/module-payloads';
import {callSuper, parseMoment} from '@/shared/helpers';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';
import TimePicker from '@/shared/components/elements/time-picker.vue';
import {actionsTypes as settingsActions} from '@/modules/settings/shared/state';
import { getSettings } from '../shared/services/settings';

@Component({
    components: {
        EventForm,
        EventDetails,
        ActionConfirmDialog,
        SystemBar,
        TooltipBtn,
        TimePicker,
    },

    props: {
        itemData: Object,
    }
})

export default class CalendarList extends ListComponent<CalendarEvent, CalendarEventDataRequest> {
    public actionsTypes = calendarModule.actionsTypes;
    public headers: object[] = [];
    public fetchAction: string = this.actionsTypes.FETCH_DATA;
    public removeAction: string = this.actionsTypes.REMOVE_ITEM;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public props: ModuleProps = calendarModule.moduleProps;
    public mutationTypes = calendarModule.mutationsTypes;
    public calendar: Calendar | null = null;
    public store = this.$store.state.calendarState;
    public x: number = 0;
    public y: number = 0;
    public isDragged: boolean = false;
    public currentEvent: EventApi | null = null;
    public editedEvent: EventApi | null = null;
    public calendarHeight: number = 0;
    public viewTypes = viewTypes;
    public settingsFormDialog = false;

    public initialView = '';
    public eventDuration = '';
    public startWorkday = '';
    public endWorkday = '';
    public mobileMenu = false;

    public ticksLabels: any = [
        {name: '15 min', number: '0.25'},
        {name: '30 min', number: '0.5'},
        {name: '45 min', number: '0.75'},
        {name: '60 min', number: '1'},
        {name: '90 min', number: '1.5'},
    ];
    public isMApp(val: any) {
        return val === 'sm' || val === 'xs';
    }

    public calendarOptions(): CalendarOptions {
        return {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin, bootstrapPlugin],
        initialView: this.initialView,
        locales: [plLocale],
        locale: 'pl',
        nowIndicator: true,
        timeZone: 'local',
        editable: false,
        dayMaxEvents: true,
        height: '100%',
        snapDuration: {milliseconds: 900000},
        nextDayThreshold: '00:00:00',
        firstDay: 1,
        headerToolbar: {
            left: '',
            center: '',
            right: ''
        },
        businessHours: {
            startTime: this.startWorkday, // a start time (10am in this example)
            endTime: this.endWorkday, // an end time (6pm in this example)
        },

        windowResize: () => {
            this.setCalendarHeight();
            if (this.formDialog && this.isDocked && window.innerWidth <= 1250) {
                this.dashToDock();
            }
        },

        nowIndicatorContent: (arg: NowIndicatorContentArg) => {
            return {html: '<div class="now-circle"></div>'};
        },

        views: {
            day: {
                viewClassNames: 'day-view',
                dayHeaderContent: (arg: DayHeaderContentArg) => {
                    const dayText = arg.text.split(' ');
                    const weekday = document.createElement('span');
                    weekday.innerText = dayText[0];
                    const dayNumber = document.createElement('div');
                    dayNumber.innerText = dayText[1];
                    dayNumber.className = 'day-number mx-auto my-2';
                    return {domNodes: [weekday, dayNumber]};
                },
            },
            week: {
                viewClassNames: 'week-view',
                dayHeaderContent: (arg: DayHeaderContentArg) => {
                    const dayText = arg.text.split(' ');
                    const weekday = document.createElement('span');
                    weekday.innerText = dayText[0];
                    const dayNumber = document.createElement('div');
                    dayNumber.innerText = dayText[1];
                    dayNumber.style.zIndex = '10';
                    dayNumber.className = 'day-number mx-auto my-2';
                    dayNumber.onmousedown = () => {
                        this.calendar?.changeView('timeGridDay');
                        this.calendar?.gotoDate(arg.date);
                    };
                    return {domNodes: [weekday, dayNumber]};
                },
            },
            timeGrid: {
                dayHeaderFormat: {weekday: 'short', day: 'numeric', omitCommas: true},
                slotLabelFormat: {
                    hour: 'numeric',
                    minute: '2-digit',
                    meridiem: 'short'
                },
                allDayText: '',
                allDayClassNames: 'all-day-header',

            },
            dayGrid: {
                dayHeaderContent: (arg: DayHeaderContentArg) => {
                    return {html: arg.text.slice(0, -1)};
                }
            },
            list: {
                listDayFormat: {weekday: 'long', day: 'numeric', omitCommas: true},
                dayHeaderContent: (arg: DayHeaderContentArg) => {
                    const dayText = arg.text.split(' ');
                    const weekday = document.createElement('span');
                    weekday.innerText = dayText[0];
                    const dayNumber = document.createElement('div');
                    dayNumber.innerText = dayText[1];
                    dayNumber.className = 'day-number mx-auto my-2';
                    dayNumber.onclick = () => {
                        this.calendar?.changeView('timeGridDay');
                        this.calendar?.gotoDate(arg.date);
                    };
                    return {domNodes: [weekday, dayNumber]};
                },
            }
        },

        dateClick: this.newEvent,
        eventClick: this.showEvent,
        eventResize: this.resizeEvent,
        eventDrop: this.dropEvent,
        eventClassNames: ({event}) => {
            return ['calendar-event', ('event-' + event.id)];
        },

        eventSources: [
            {
                color: 'primary',
                id: 'current-user',
                events: (fetchInfo, successCallback, errorCallback) => {
                    this.$store.dispatch(this.actionsTypes.FETCH_DATA, {
                        simple: false,
                        filters: {
                            start_date: this.parseDateTime(fetchInfo.start),
                            end_date: this.parseDateTime(fetchInfo.end)
                        }
                    })
                        .then((response) => {
                            if (response.data) {
                                const events = this.items.map((event: CalendarEvent) => this.parseEvent(event));
                                successCallback(events);
                            }
                        })
                        .catch(errorCallback);
                },
            },
            {
                color: '#777',
                className: 'edited-event',
                id: 'edited-event',
                events: [],
            }
        ],
        };
    }

    public eventAssignable(search: string) {
        return eventAssignables.find((assignable) => assignable.type === search);
    }

    public parseEvent(event: CalendarEvent, edit?: boolean): EventInput {
        const endDate = parseMoment(event.end_date);
        if (event.all_day) {
            endDate.add(1, 'day'); // Added because of problems with all_day property
        }

        const parsedEvent: EventInput = {
            id: event.id ? String(event.id) : 'new',
            title: event.name || '',
            start: event.start_date,
            end: endDate.toDate(),
            allDay: event.all_day,
        };

        if (!edit && event.assignable_type) {
            const assignableType = this.eventAssignable(event.assignable_type);
            parsedEvent.color = assignableType?.color;
        }

        return parsedEvent;
    }

    public setCalendarHeight() {
        this.calendarHeight = window.innerHeight - (this.isDocked ? 65 : 128);
    }

    get isDocked(): boolean {
        return this.$store.state.hideNavigationBar;
    }

    set isDocked(val: boolean) {
        this.$store.commit('UPDATE_NAVIGATION_BAR', val);
    }

    get currentData(): CalendarData | null {
        return this.calendar ? this.calendar.getCurrentData() : null;
    }

    get currentUserEvents(): EventSourceApi | null {
        return this.calendar ? this.calendar.getEventSourceById('current-user') : null;
    }

    get user() {
        return this.$store.state.authState.user;
    }

    public eventRemoved(status: number) {
        if (this.currentUserEvents && status === 204) {
            if (this.calendar) {
                const calendarEvent = this.calendar.getEventById(String(this.current.id));
                if (calendarEvent) {
                    calendarEvent.remove();
                }
            }
            this.showDialog = false;
        }
    }

    public newEventFromButton() {
        const startDate = new Date();
        const endDate = new Date(startDate.toString());
        const eventDuration = Number(this.eventDuration);

        if (eventDuration === 1) {
            endDate.setHours(startDate.getHours() + eventDuration);
        } else {
            if (eventDuration === 0.25) {
                endDate.setMinutes(startDate.getMinutes() + 15);
            }
            if (eventDuration === 0.5) {
                endDate.setMinutes(startDate.getMinutes() + 30);
            }
            if (eventDuration === 0.75) {
                endDate.setMinutes(startDate.getMinutes() + 45);
            }
            if (eventDuration === 1.5) {
                endDate.setMinutes(startDate.getMinutes() + 90);
            }
        }

        const itemTemplate = {
            name: '',
            start_date: this.parseDateTime(startDate),
            end_date: this.parseDateTime(endDate),
            all_day: false,
        };
        if (this.calendar) {
            this.editedEvent = this.calendar.addEvent(
                this.parseEvent(itemTemplate as CalendarEvent, true),
                'edited-event'
            );
            this.calendar.gotoDate(startDate);
            this.calendar.changeView('timeGridDay');
            setTimeout(() => {
                this.setFormView(document.getElementsByClassName('event-new')[0]);
            }, 100);
            this.createItem(itemTemplate as CalendarEventDataRequest);
        }
    }

    public created() {
        this.setCalendarHeight();
    }

    public mounted() {
        getSettings().then((response) => {
            this.initialView = response.initial_view;
            this.eventDuration = response.event_duration;
            this.startWorkday = response.start_workday;
            this.endWorkday = response.end_workday;
            const calendarEl = document.getElementById('calendar');
            if (calendarEl) {
                this.calendar = new Calendar(calendarEl, this.calendarOptions());
                this.calendar.render();
            }
        });
    }

    public editItem(item: CalendarEvent | CalendarEventDataRequest) {
        this.showDialog = false;
        if (this.currentEvent) {
            this.currentEvent.remove();
        }

        callSuper(this, 'editItem', item);
    }

    public createItem(item?: CalendarEventDataRequest) {
        this.showDialog = false;
        callSuper(this, 'createItem', item);
    }

    public setFormView(el: Element, jsEvent?: MouseEvent) {
        const clientReact = el.getBoundingClientRect();
        const cardX = (clientReact.x + clientReact.width) + 500;

        if (this.calendar) {
            const view = this.calendar.view;
            this.y = clientReact.y;

            switch (view.type) {
                default:
                    if (cardX < window.innerWidth) {
                        this.x = clientReact.x + clientReact.width;
                    } else {
                        this.x = (clientReact.x) - 500;
                    }
                    break;
                case 'timeGridDay':
                    this.x = (clientReact.x + clientReact.width) / 2;
                    if (jsEvent) {
                        this.y = jsEvent.y;
                    }
                    break;

                case 'listWeek':
                    this.x = (clientReact.x + clientReact.width) / 2;
                    if (jsEvent) {
                        this.y = jsEvent.y;
                    }
                    break;
            }
        }
    }

    public showEvent({event, el}: EventClickArg) {
        if (event.source && event.source.id !== 'edited-event' && !this.formDialog) {
            this.formDialog = this.isDocked;
            this.currentEvent = event;
            this.setFormView(el);

            const clickedEvent = this.items.find((e) => e.id === Number(event.id));
            if (clickedEvent) {
                this.showItem(clickedEvent);
            }
        }
    }

    public resizeEvent(e: EventResizeDoneArg) {
        const source = e.event.source;
        const editedEvent = {
            id: Number(e.event.id),
            all_day: e.event.allDay,
        } as CalendarEvent;

        const startDate = new Date(e.event.startStr);
        if (e.startDelta) {
            startDate.setMilliseconds(startDate.getMilliseconds() + e.startDelta.milliseconds);
            startDate.setMonth(startDate.getMonth() + e.startDelta.months);
            startDate.setHours(startDate.getHours() + (e.startDelta.days * 24));
            startDate.setFullYear(startDate.getFullYear() + e.startDelta.years);
        }

        editedEvent.start_date = !editedEvent.all_day ? this.parseDateTime(startDate) : this.parseDate(startDate);

        const endDate = parseMoment(e.event.endStr);
        if (editedEvent.all_day) {
            endDate.subtract(1, 'day');
        }
        editedEvent.end_date = !editedEvent.all_day ?
            this.parseDateTime(endDate.toDate()) :
            this.parseDate(endDate.toDate());

        if (source) {
            switch (source.id) {
                case 'edited-event':
                    this.editedItem.start_date = editedEvent.start_date;
                    this.editedItem.end_date = editedEvent.end_date;
                    this.editedItem.all_day = editedEvent.all_day;
                    break;
                default:
                    this.$store.dispatch(this.actionsTypes.UPDATE_ITEM, editedEvent).then(this.updateCallback);
                    break;
            }
        }
    }

    public updateCallback({data}: ModuleShowPayload<CalendarEvent>) {
        if (data) {
            const index = this.items.findIndex((event) => data.id === event.id);
            this.$set(this.items, index, data);
            if (this.current.id === data.id) {
                this.current = data;
            }
        }
    }

    public dropEvent(e: EventDropArg) {
        const source = e.event.source;
        const editedEvent = {
            id: Number(e.event.id),
            all_day: e.event.allDay,
        } as CalendarEvent;

        const startDate = new Date(e.event.startStr);

        editedEvent.start_date = !editedEvent.all_day ? this.parseDateTime(startDate) : this.parseDate(startDate);

        if (e.event.endStr) {
            const endDate = parseMoment(e.event.endStr);
            if (editedEvent.all_day) {
                endDate.subtract(1, 'day');
            }
            editedEvent.end_date = !editedEvent.all_day ?
                this.parseDateTime(endDate.toDate()) :
                this.parseDate(endDate.toDate());
        }

        if (source) {
            switch (source.id) {
                case 'edited-event':
                    this.editedItem.start_date = editedEvent.start_date;
                    this.editedItem.end_date = editedEvent.end_date;
                    this.editedItem.all_day = editedEvent.all_day;
                    break;
                default:
                    this.$store.dispatch(this.actionsTypes.UPDATE_ITEM, editedEvent).then(this.updateCallback);
                    break;
            }
        }
    }

    public closeForm() {
        if (this.isDocked) {
            this.isDocked = false;
            const event = document.querySelector('.event-' + this.editedItem.id || 'new');
            if (event) {
                this.setFormView(event);
            }
        }
        callSuper(this, 'closeForm', );
    }

    public itemSaved({data, next}: SaveResponse<CalendarEvent>) {
        if (!next) {
            this.closeForm();
        } else {
            this.editedItem = {
                name: '',
                start_date: data.end_date,
                all_day: data.all_day,
            } as CalendarEventDataRequest;

            const endDate = parseMoment(data.end_date);
            endDate.add(1, 'hour');
            this.editedItem.end_date = this.parseDateTime(endDate.toDate());

            if (this.calendar) {
                if (this.editedEvent) {
                    this.editedEvent.remove();
                    this.editedEvent = null;
                }
                this.editedEvent = this.calendar.addEvent(
                    this.parseEvent(this.editedItem as unknown as CalendarEvent, true),
                    'edited-event'
                );
            }
        }
        if (this.currentUserEvents) {
            if (this.calendar) {
                this.calendar.addEvent(this.parseEvent(data), this.currentUserEvents);
                const eventIndex = this.items.findIndex((e) => e.id === data.id);
                if (eventIndex !== -1) {
                    this.$set(this.items, eventIndex, data);
                } else {
                    this.items.push(data);
                }
            }
        }
    }

    public newEvent(arg: DateClickArg) {
        const target = arg.jsEvent.target as Element;

        if (target && !target.classList.contains('fc-daygrid-day-number')) {
            if (!this.formDialog || this.isDocked) {
                if (!this.isDocked) {
                    this.editedItem = {} as CalendarEventDataRequest;
                    this.setFormView(arg.dayEl, arg.jsEvent);
                    const item = {
                        name: '',
                        start_date: this.parseDateTime(arg.dateStr),
                        all_day: arg.allDay,
                        end_date: this.parseDateTime(arg.dateStr),
                    } as CalendarEventDataRequest;

                    if (!item.all_day) {
                        const date = parseMoment(arg.date);
                        // date.add(1, 'hours');
                        date.add(Number(this.eventDuration), 'hours');
                        item.end_date = this.parseDateTime(date.toDate());
                    }

                    this.createItem(item);
                } else {
                    this.$set(this.editedItem, 'start_date', this.parseDateTime(arg.dateStr));
                    const date = parseMoment(arg.date);
                    // date.add(1, 'hours');
                    date.add(Number(this.eventDuration), 'hours');
                    this.$set(this.editedItem, 'end_date', this.parseDateTime(date.toDate()));
                    this.$set(this.editedItem, 'all_day', false);
                }
            } else {
                this.closeForm();
            }
        } else {
            this.calendar?.changeView('timeGridDay');
            this.calendar?.gotoDate(arg.date);
        }
    }

    public dashToDock() {
        if (this.isDocked) {
            this.isDocked = false;
            setTimeout(() => {
                const event = document.getElementsByClassName('event-' + (this.editedItem.id || 'new'))[0];
                if (event) {
                    this.setFormView(event);
                }
            }, 100);
        } else {
            this.isDocked = true;
            this.onDraggedChange(false);
        }
    }

    public openSettings() {
        this.settingsFormDialog = true;
    }

    public saveSettings() {
        const initialView = this.initialView;
        this.$store.dispatch(settingsActions.UPDATE_SETTING, {
            key: 'initial_view_' + this.user.id,
            value: initialView
        });

        const eventDuration = this.eventDuration;
        this.$store.dispatch(settingsActions.UPDATE_SETTING, {
            key: 'event_duration_' + this.user.id,
            value: eventDuration
        });

        const startWorkday = this.startWorkday;
        this.$store.dispatch(settingsActions.UPDATE_SETTING, {
            key: 'start_workday_' + this.user.id,
            value: startWorkday
        });

        const endWorkday = this.endWorkday;
        this.$store.dispatch(settingsActions.UPDATE_SETTING, {
            key: 'end_workday_' + this.user.id,
            value: endWorkday
        }).then(() => {
            if (this.calendar) {
                this.calendar.destroy();
                setTimeout(() => {
                    getSettings().then((response) => {
                        this.initialView = response.initial_view;
                        this.eventDuration = response.event_duration;
                        this.startWorkday = response.start_workday;
                        this.endWorkday = response.end_workday;
                        const calendarEl = document.getElementById('calendar');
                        if (calendarEl) {
                            this.calendar = new Calendar(calendarEl, this.calendarOptions());
                            this.calendar.render();
                        }
                    });
                }, 1000);
            }
        });
    }

    @Watch('editedItem', {deep: true})
    public editedItemChanged(editedItem: CalendarEventDataRequest) {
        if (this.formDialog && this.calendar) {
            if (!this.editedEvent) {
                this.editedEvent = this.calendar.addEvent(
                    this.parseEvent(editedItem as unknown as CalendarEvent, true),
                    'edited-event'
                );
            } else {
                this.editedEvent.setProp('title', editedItem.name);
                this.editedEvent.setStart(editedItem.start_date);
                if (editedItem.end_date) {
                    const endDate = parseMoment(editedItem.end_date);
                    if (editedItem.all_day) {
                        endDate.add(1, 'day');
                    }
                    this.editedEvent.setEnd(endDate.toDate());
                }
                if ('all_day' in editedItem) {
                    this.editedEvent.setAllDay(editedItem.all_day);
                }
            }
        }
    }

    @Watch('x')
    public onXChange() {
        if (this.formDialog && window.innerWidth >= 1250) {
            this.isDocked = this.x <= 256;
        }
    }

    @Watch('isDocked')
    public onIsDockedChange() {
        setTimeout(() => {
            if (this.calendar) {
                this.calendar.updateSize();
                this.setCalendarHeight();
            }
        }, 100);
    }

    @Watch('isDragged')
    public onDraggedChange(value: boolean) {
        if (this.isDocked && !value) {
            this.x = 0;
            this.y = 0;
            if (this.calendar) {
                this.calendar.gotoDate(this.editedItem.start_date);
                if (!['timeGridDay', 'timeGridWeek'].includes(this.calendar.view.type)) {
                    this.calendar.changeView('timeGridDay');
                }
            }
        }
    }

    @Watch('formDialog')
    public formDialogChanged(newVal: boolean) {
        if (this.calendar) {
            if (!newVal) {
                if (this.editedEvent) {
                    this.editedEvent.remove();
                    this.editedEvent = null;
                }
                if (this.currentUserEvents) {
                    const editedEvent = this.items.find((item) => item.id === this.editedItem.id);
                    if (editedEvent && !this.calendar.getEventById(String(this.editedItem.id))) {
                        this.currentEvent = this.calendar.addEvent(
                            this.parseEvent(editedEvent),
                            this.currentUserEvents
                        );
                    }
                }
            }
        }
    }

    public beforeDestroy() {
        this.showDialog = false;
        this.formDialog = false;
        if (this.calendar) {
            this.isDocked = false;
            this.formDialog = false;
            this.calendar.destroy();
        }
    }
}

